crm login starts here a {
    text-decoration: none;
}

.crm-header-block {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 5px;
}

.crm-header-profile-block {
    display: flex;
    align-items: center;
    gap: 15px;
    grid-gap: 15px;
}

.crm-header-profile-block-item.profile img {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.crm-header-profile-block-item a {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 3px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
}

.crm-header-profile-block-item.sign-out a {
    color: #77828F;
    background: rgba(235, 235, 235, 0.4);
}

.crm-header-profile-block-item.take-break a {
    background: rgba(255, 244, 215, 0.4);
    color: #532A1E;
}

.crm-header-profile-block-item.working-time a {
    background: rgba(195, 237, 216, 0.3);
    color: #0F1E54;
}

.crm-header-profile-block-item.alert-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(119, 130, 143, 0.78);
    padding-right: 0;
}

.crm-login-alert-block {
    display: flex;
    align-items: center;
    gap: 15px;
    grid-gap: 15px;
}

.login-alert-icon {
    position: relative;
    display: flex;
    background: #E3F0FF;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.login-alert-icon::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background: conic-gradient(from 180deg at 50% 50%, #1C83FB 0deg, #C8E0FF 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
}

.login-alert-icon.on-time::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background: conic-gradient(from 180deg at 50% 50%, #16CC70 0deg, #E4F9EA 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
}

.login-alert-icon.late-time::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    background: conic-gradient(from 180deg at 50% 50%, #F8434E 0deg, rgba(255, 217, 217, 0) 360deg);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 50%;
}



.login-alert-text {
    border-radius: 31px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 25px;
}

.cr-time {
    background: rgba(105, 174, 255, 0.19);
    color: #0F1E54;
}

.on-time {
    background: #E4F9EA;
}

.late-time {
    background: rgb(255 240 240);
}

.login-alert-text.on-time b {
    color: #16CC70;
}

.login-alert-text.late-time b {
    color: #F8434E !important;
}

a.close {
    margin-left: 20px;
}

/*crm login end here*/




.css-1vgj6wr-MuiButtonBase-root-MuiButton-root,
.css-1yo9kdb-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.css-15puwmf-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-k9x1d7-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-aaamal-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-ui6e1j-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.css-svf30e-MuiButtonBase-root-MuiButton-root,
.css-1rt8pkh-MuiButtonBase-root-MuiButton-root,
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-146lx5y-MuiButtonBase-root-MuiButton-root {
    min-width: 90% !important;
}


td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall.css-q6eo78-MuiTableCell-root button {
    min-width: 90% !important;
}

tr.MuiTableRow-root.css-18qx59p-MuiTableRow-root:hover {
    background-color: rgb(243, 242, 255);
    transition: .25s;
}

.task-assigned-to-me div {
    color: rgb(255, 100, 46);
}

/* .ass-me-block{
    border-left: 4px solid rgb(255, 100, 46);
} */

.task-assigned-by-me div {
    color: rgb(87, 155, 252);
}

/* .task-assigned-by-me .ass-me-block {
    border-left: 4px solid rgb(87, 155, 252);
} */

.to-review div {
    color: rgb(162, 93, 220);
}

.task-iam-subscribed-to div {
    color: #77828F;
}


.public-DraftStyleDefault-ltr {
    margin: 0 !important;
}

.css-1lsx91t-MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 !important;
    margin: 0 !important;
}

.css-1lsx91t-MuiDataGrid-root .MuiDataGrid-columnHeader,
.css-1lsx91t-MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 !important;
}

.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    padding: 0px 16px !important;
}

.MuiDataGrid-columnHeaderDraggableContainer {
    padding: 6px 16px;
    color: #03060b;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    background-color: #2d2638 !important;
    transition: .25s;
    height: calc(100% - 0px) !important;
}

.css-3uyw9r {
    height: 100% !important;
    min-height: 100vh !important;
}

li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding {
    background-color: transparent;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft:hover {
    background-color: #2d2638 !important;
    /* background-color: #fff !important; */
    transition: .25s;
}

.css-j9114q-MuiButtonBase-root-MuiButton-root .MuiButton-startIcon {
    color: #f3f4f7;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    /* fill: #181616 !important; */
    transition: .25s;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft:hover svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    /* fill: #3e3e3d !important; */
    transition: .25s;
}

.MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiCard-root {
    margin-left: 16px;
    margin-right: 16px;
}

.MuiDataGrid-columnHeaders {
    background-color: #f1f1f1;
    border-bottom: 1px solid #e1e3ea;
}

.MuiDataGrid-row {
    max-height: 40px !important;
    min-height: 40px !important;
    align-items: center;
    border-bottom: 1px solid #e1e3ea;
}

.MuiDataGrid-row {
    width: 100% !important;
}

.MuiDataGrid-virtualScrollerRenderZone.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone {
    width: 100% !important;
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
    min-height: 40px !important;
    max-height: 40px !important;
    padding: 0 15px !important;
    text-align: left !important;
    /* width: 24% !important;
    min-width: 24% !important;
    max-width: 24% !important;
    flex: 0 0 24% !important; */
}

.MuiDataGrid-cell {
    height: 40px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall {
    justify-content: flex-start !important;
    color: #000;
    width: 90%;
}

.css-svf30e-MuiButtonBase-root-MuiButton-root {
    color: #fff !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeSmall.MuiButton-containedSizeSmall {
    color: #fff !important;
}

.MuiDataGrid-virtualScrollerContent {
    height: auto !important;
    min-height: 450px !important;
}

.css-ucizrx-MuiListItem-root {
    padding-left: 0px !important;
}

li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding {
    padding-left: 0px !important;
    margin-left: 8px;
}

ul.table-tools-icon-block {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.TableTools-top-block {
    display: flex;
    align-items: center;
}

ul.table-tools-icon-block li svg {
    width: 50px !important;
    height: 50px !important;
    padding-top: 10px;
}

ul.table-tools-icon-block li {
    cursor: pointer;
}

.css-17zm78u {
    background-color: #fffefa !important;
    position: relative;
}

/* .portal-footer {
    position: absolute;
    bottom: -15px;
    width: 80%;
    left: 20%;
    transform: translate(-10%, 0px);
} */

.loadingScreen {
    width: '100%';
    display: flex;
    justify-content: center;
    margin-top: 70px;
}

.MuiDataGrid-columnHeaders {
    background-color: #f3f4f7;
    border-bottom: 1px solid #e1e3ea;
    min-height: 40px !important;
    max-height: 40px !important;
    line-height: 40px !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable {
    height: 40px !important;
}

.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
    display: none !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #506176;
}

.css-gl260s-MuiDataGrid-columnHeadersInner {
    width: 100%;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    width: 100%;
}

/* .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable{
    width: 24% !important;
    min-width: 24% !important;
    max-width: 24% !important;
    flex: 0 0 24% !important;
} */

/* .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable:last-of-type{
    width: 4% !important;
    min-width: 4% !important;
    max-width: 4% !important;
    flex: 0 0 4% !important;
} */

.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller {
    margin-top: 40px !important;
}

.MuiDataGrid-row {
    border-bottom: 1px solid #e1e3ea !important;
}

.css-1lsx91t-MuiDataGrid-root .MuiDataGrid-cell {
    border-bottom: 0px !important;
}


.MuiDataGrid-row:nth-child(even) {
    background-color: rgb(243, 242, 255);
    transition: .25s;
}

.MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityComfortable {
    border-left: 4px solid rgb(255, 100, 46);
}

.backlink-title-block {
    justify-content: space-between !important;
    padding: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    margin-top: 25px;
    align-items: center !important;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100%;
    background-color: #fff;
    max-width: 1000px;
}

/* .css-1uhcnue-MuiButtonBase-root-MuiButton-root {
    background-color: #263750 !important;
    color: #04d9ab !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained{
    background-color: #263750 !important;
    color: #04d9ab !important;
} */



td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall {
    border-right: 2px solid #f3f4f7 !important;
    border-bottom: 2px solid #f3f4f7 !important;
}

th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall.link {
    border-right: 2px solid #f3f4f7 !important;
    border-bottom: 2px solid #f3f4f7 !important;
}


/* .projectdetail-block .MuiBox-root .MuiBox-root .MuiTabs-flexContainer {
    display: flex;
    flex-direction: column;
    width: 200px;
    text-align: left;
} */

/* .projectdetail-block .MuiBox-root .MuiBox-root .MuiTabs-flexContainer button {
    text-align: left;
    justify-content: left;
    align-items: baseline;
    padding: 0;
    margin: 0;
} */

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-8rxuq0-MuiButtonBase-root-MuiButton-root.task-btn {
    background-color: #5046e4 !important;
    justify-content: center !important;
    color: #fff !important;
}


.rdw-editor-main {
    height: 100px !important;
    border: 1px solid gray !important;
    overflow: hidden !important;
}

.notranslate.public-DraftEditor-content {
    overflow-x: hidden !important;

    padding: 10px;
}

.svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    fill: black !important;
}

/* .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.css-8rxuq0-MuiButtonBase-root-MuiButton-root{
fill: #03060b !important;
color: #03060b !important;
} */
.cra-minutes {
    width: 127%;
    margin-left: 27px;
}

.no-hover-effect:hover {
    background-color: transparent !important;
    /* Set the hover background color to transparent */
}

#loader-circle {
    width: 100%;
    margin-top: 80px;
    height: 50px;
    display: flex;
    justify-content: center;
}

.card-block-web-content .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
    margin-left: 0;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.MuiGrid-grid-md-4.MuiGrid-grid-xl-3.card-block-web a {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: rgb(80, 97, 118);
}

.occurrence-block.MuiBox-root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

p.MuiTypography-root.MuiTypography-body1.occurrence-block-title {
    width: 100%;
    font-size: 14px;
}

p.MuiTypography-root.MuiTypography-body1.occurrence-block-item {
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 5px;
    color: #fff !important;
}

.block-item-title {
    font-size: 14px !important;
}

p.MuiTypography-root.MuiTypography-body1.block-title {
    font-size: 14px;
}

.card-block-web .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    background: rgba(235, 235, 235, 0.6);
}

@keyframes loading-animation {
    0% {
        border-color: #d3d1e0;
    }

    50% {
        border-color: #e0e2ed;
    }

    100% {
        border-color: #541d9b;
    }
}

.scrollable-container {
    max-height: 450px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent; /* For Firefox */
  }
  
  .scrollable-container::-webkit-scrollbar {
    width: 6px; /* For Chrome, Safari, and Opera */
  }
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  