.hover-row:hover .hover-icon,
.hover-row:hover .row-user-name {
  visibility: visible;
}

.hover-icon {
  visibility: hidden;
}

.row-user-name {
  visibility: hidden;
}

/* .title-row-hover button:hover{
    visibility: hidden;
    cursor: pointer;
  } */